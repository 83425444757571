@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF-Pro-Display-Light';
  src: url(./assets/fonts/SFProDisplay/SFProDisplay/SF-Pro-Display-Light.otf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131620;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div,
p,
span,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
}

.width-correct-score {
  width: 12.5%;
}

.key-frame-light-green {
  animation-name: example;
  animation-duration: 5s;
}

.key-frame-light-red {
  animation-name: examplered;
  animation-duration: 5s;
}

@keyframes example {
  0% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }

  10% {
    background-color: black;
  }

  20% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }

  30% {
    background-color: black;
  }

  40% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }

  50% {
    background-color: black;
  }

  60% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }

  70% {
    background-color: black;
  }

  80% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }

  90% {
    background-color: black;
  }

  100% {
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);
  }
}

@keyframes examplered {
  0% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  10% {
    background-color: black;
  }

  20% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  30% {
    background-color: black;
  }

  40% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  50% {
    background-color: black;
  }

  60% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  70% {
    background-color: black;
  }

  80% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }

  90% {
    background-color: black;
  }

  100% {
    background: linear-gradient(180deg, #BB2C38 0%, rgba(0, 0, 0, 0.1) 100%);
  }
}

.collapse-soccer-live {
  width: 100%;
}

.collapse-soccer-live .ant-collapse-item .ant-collapse-header {
  width: 100%;

}

.collapse-soccer-live .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}