.tab-bet-home .ant-tabs-content-holder {
  max-height: 500px;
  overflow: auto;
}

.tab-bet-home .ant-tabs-content-holder::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 6px;
}

.tab-bet-home .ant-tabs-content-holder::-webkit-scrollbar-thumb {
  /* background-image: linear-gradient(45deg, #BB271A, #000); */
  background: #D4B962;
  border-radius: 16px;
}


.betTabs::-webkit-scrollbar {
  display: block !important;
  width: 6px;
  height: 6px;
}

.betTabs::-webkit-scrollbar-thumb {
  /* background-image: linear-gradient(45deg, #BB271A, #000); */
  background: #D4B962;
  border-radius: 16px;
}

.betTabs {
  max-height: 500px;
  overflow: auto;
  /* position: relative; */
  padding-bottom: 10px;
  padding-right: 10px;
}