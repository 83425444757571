.headTab .ant-tabs-tab .ant-tabs-tab-btn {
    color: #FFFFFF !important;
    font-size: 18px;
}

.headTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF !important;
    font-weight: 500;
}

.headTab .ant-tabs-ink-bar {
    background: #FFFFFF !important;
}

.headTab .ant-tabs-nav::before {
    display: none;
}

.tabsNavi .ant-btn-default:hover {
    border-color: #BB271A !important;
    background-color: rgba(255, 0, 0, 0.2) !important;
}