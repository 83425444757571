.statusBar .ant-radio-button-checked {
    background-color: #BB271A;
}

.statusBar .ant-radio-button {
    border-radius: 6px !important;
}

.statusBar .ant-radio-button-wrapper {
    border: unset !important;
}

.statusBar .ant-radio-button-wrapper-checked .ant-space-item {
    color: white !important;
    border: #BB271A !important;
}

.statusBar {
    margin-top: 24px;
    border-radius: 6px !important;
    margin-right: 8px;
}

.statusBar .w-169 {
    width: 169px !important;
}

.statusBar .w-200 {
    width: 200px !important;
}

.statusBar .ant-radio-button-wrapper::before {
    background-color: #FFFFFF !important;
}

.dayTime {
    gap: 20px;
}

.decimal {
    padding: 0px 0px 10px 0px;
}

.decimal .ant-space-item {
    max-height: 100px;
}

.winBar {
    margin-top: 24px;
    border-radius: 6px !important;
    background: linear-gradient(90deg, #BB271A 0%, #000000 100%);
    padding-right: 18px;
}

.winBar .ant-space-item {
    color: white;
    font-weight: 700;
    padding: 0px 50px;
    max-width: 110px;
}

.winBar.winBarTotal {
    background: #E5D5A1 !important;
    border-radius: 6px 6px 0px 0px !important;
}

.winBar.soccerBar.winBarTotal .ant-space-item {
    padding: 0 !important;
    color: black;
}

.gameDetail {
    padding: 10px 12px 10px 22px;
    margin-bottom: 8px;
    background-color: #131620;
    border-radius: 16px;
}

.gameLiveDetail {
    padding: 10px 12px 10px 22px;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 16px;
}

/* .ratioDetail p {
    max-height: 28px;
    min-width: 98px;
}

.ratioDetail p {
    max-height: 28px !important;
    min-width: 49px !important;
} */

.ratioDetail {
    margin: 8px 4px;
    padding: 10px 0px;
    border-radius: 6px;
    border: 1px solid var(--LINE-Line-02, #E7E7E7);
    background: var(--Black-01, #FFF);
}

.ratioDetail.ratioDetailSoccer {
    margin: 4px 4px;
    padding: 0px;
    border-radius: 6px;
    border: 1px solid #202531;
    background: #131620;
}

.ratioDetail.ratioDetailSoccerlive {
    margin: 4px 4px;
    padding: 0px;
    border-radius: 6px;
    border: 1px solid #202531;
    background: #131620;
}

/* .custom-border-live {
    border-radius: 5px;
    box-shadow: 3px 3px 20px #ddd;
    position: relative;
}

.ratioDetail.custom-border-green::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-right: 5px solid greenyellow;
    border-top: 5px solid greenyellow;
}

.ratioDetail.custom-border-red::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-right: 5px solid red;
    border-bottom: 5px solid red;
} */

.liveCam {
    width: 146px;
}

.preMatch {
    width: 180px;
}

.bg-D4B962 {
    /* background-color: #D4B962; */
    background: linear-gradient(180deg, rgba(51, 228, 154, 0.44) 0%, rgba(0, 0, 0, 0.25) 100%);

}

.imageBlack {
    fill: black;
    filter: invert(1);
}

.winBar.soccerBar .ant-space-item {
    padding: 0 38px;
    height: 32px;
    display: flex;
    align-items: center;
}

.winBar.soccerBar .topStatus .ant-space-item {
    max-width: unset;
}

.gameDetail.soccerGame {
    padding: 10px 12px 10px 12px;
}

.gameDetail.soccerGame {
    border-radius: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.gameLiveDetail.soccerGame {
    padding: 10px 12px 10px 12px;
}

.gameLiveDetail.soccerGame {
    border-radius: unset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

/* .soccerGame .flex.borderLine {
    border-bottom: 1px solid #E7E7E7;
} */

.soccerBottom .ratioDetail {
    margin: 8px 2px 8px 0px;
}

.preMatch .ant-radio-button.ant-radio-button-checked {
    position: absolute;
    left: 0px;
}

.liveCam .ant-radio-button.ant-radio-button-checked {
    position: absolute;
    left: 0px;
}

.decimal .ant-select-arrow {
    position: absolute;
    right: 20px;
}

.dayTime .ant-radio-inner::after {
    position: absolute;
    top: 7px;
    right: -1px;
}

.liveCam span div {
    column-gap: unset !important;
}

.collapse-league {
    padding: 16px !important;
    padding: 0 !important;
    background-color: #131620 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.collapse-league.ant-collapse {
    border: unset !important;
}

.collapse-league.ant-collapse .ant-collapse-content {
    background-color: #131620 !important;
    border-top: unset !important;
}

.collapse-league .ant-collapse-item .ant-collapse-header {
    padding: 8px 12px !important;
    background-color: #131620 !important;
    border-radius: 20px !important;

}

.collapse-soccer-live .ant-collapse-item .ant-collapse-header {
    background-color: #131620 !important;
}